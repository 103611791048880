import React, { useContext, useEffect } from "react";
import CustomPriceContext from "../customPriceContext";
import Gallery from "./components/gallery";
import RelatedProducts from "./components/relatedProducts";
import Toolbar from "../components/toolbar";
import Options from "../components/options";
import Specs from "../components/specs";
import ProductCMS from "../components/cms";
import Breadcrumbs from "app/layout/breadcrumbs";
import { useMediaQuery } from "react-responsive";
import RoomScenes from "./RoomScenes";
import useTagboxHelper from "app/layout/Tagbox/useTagboxHelper";
import CmsBlock from "app/layout/cmsBlock";

const GenericPDP = ({
  product,
  loading,
  optionState,
  optionDispatch,
  availableIn,
  mappedSpecs,
  relatedProducts,
  calculateOptions,
  downloadTearSheet,
  isEdit,
  finishError,
  setFinishError,
  ...props
}) => {
  const { dispatch: priceDispatch } = useContext(CustomPriceContext);
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });

  const { tag } = useTagboxHelper();

  useEffect(() => {
    priceDispatch({
      type: "setMinPrice",
      minPrice: product?.price_range?.minimum_price?.final_price?.value || 0,
    });
  }, [priceDispatch, product?.price_range?.minimum_price?.final_price?.value]);

  function generateCollectionName(collections) {
    return collections?.length > 0 && collections[0] !== "false"
      ? collections.join(",")
      : "";
  }
  function isPelleCollection() {
    let isPelle = false;
    if (product?.collection) {
      product.collection.forEach((col) => {
        if (col.toLowerCase() === "pelle provisions") {
          isPelle = true;
        }
      });
    }
    return isPelle;
  }

  return (
    <>
      <div className={"generic-pdp"}>
        <div className="container-fluid">
          <Breadcrumbs className={"toolbar-breadcrumbs"} sku={product?.sku} />
          <div className="row">
            <div className="col-lg-8">
              <Gallery gallery={product.media_gallery} hideLabel={true} />
            </div>
            <div className="col-lg-4">
              <div className={"generic-options-container"}>
                <div className={`generic-left `}>
                  <Specs
                    availableIn={availableIn}
                    mappedSpecs={mappedSpecs}
                    collections={product.collection}
                    productSku={product?.sku}
                    isMobileOrTablet={isMobileOrTablet}
                  />
                </div>
                <div className={"generic-right"}>
                  <Options
                    options={product.options}
                    optionState={optionState}
                    optionDispatch={optionDispatch}
                    productImage={product.image}
                    sku={product.sku}
                    leatherAvailable={product.available_in_leather}
                    isPelleProvisions={isPelleCollection()}
                    ffi_fullyuph={product?.ffi_fullyuph}
                    finishOption={product.allowed_finishes}
                    product={product}
                    customName={generateCollectionName(product.collection)}
                    finishError={finishError}
                    setFinishError={setFinishError}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Toolbar
          sku={product.sku}
          name={product.name}
          calculateOptions={calculateOptions}
          downloadTearSheet={downloadTearSheet}
          id={product.id}
          image={product.image}
          product={product}
          isEdit={isEdit}
          optionState={optionState}
          availableIn={availableIn}
          customName={generateCollectionName(product.collection)}
          description={product.ffi_extdesc2}
          finishError={finishError}
          setFinishError={setFinishError}
        />
        <RelatedProducts relatedProducts={relatedProducts} />
        {/* {tag} */}
        {/* <div className="browse-residential-commercial-lookbooks-wrap mb-20">
          <CmsBlock id="browse-residential-commercial-lookbooks" />
        </div> */}

        <CmsBlock id="inspirations-lookbook-pdp" />

        <RoomScenes product={product} />
      </div>
      {/* <ProductCMS /> */}
    </>
  );
};

export default GenericPDP;
